import React, { createContext } from "react"
import pageBuilder from "./Builder/index"
import { graphql } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import _ from "lodash"
// import FontSchemeManager from "./FontScheme/index"
// import PrimeAgileHelmet from "./Helmet/index"
// import ErrorBoundary from "./ErrorBoundary/index"
import SSRProvider from "react-bootstrap/SSRProvider"
import loadable from "@loadable/component"
import { ProviderAPI } from "./PageStateContext"
import "@fortawesome/fontawesome-free/js/all.js"
import { ToastContainer } from "react-toastify"
const Adapter = loadable(() =>
  import("./Builder/Components/LoginAdapter/Adapter")
)
const FontSchemeManager = loadable(() => import("./FontScheme/index"))
const PrimeAgileHelmet = loadable(() => import("./Helmet/index"))
const ErrorBoundary = loadable(() => import("./ErrorBoundary/index"))

export const PageStateContext = createContext(null)

export default function PrimeAgile({ data, pageContext }) {
  let TREE =
    data.primeAgile.layoutWithObjectTree.uiObjectWithChildTreeR
      .uiObjectChildTree

  TREE = pageBuilder(_.sortBy(TREE, ["objectOrder"]))

  return (
    <>
      <SSRProvider>
        <ErrorBoundary>
          <ProviderAPI value={pageContext} id="primeagile__entry">
            <FontSchemeManager fontScheme={pageContext.fontScheme}>
              <PrimeAgileHelmet pageContext={pageContext} />
              <ToastContainer />
              {pageContext?.hasCustomerLogin ? (
                <Adapter>
                  <>{TREE}</>
                </Adapter>
              ) : (
                <> {TREE} </>
              )}
            </FontSchemeManager>
          </ProviderAPI>
        </ErrorBoundary>
      </SSRProvider>
    </>
  )
}

export const query = graphql`
  query layoutTree($site: String, $objectDataDefinitionId: String) {
    primeAgile {
      layoutWithObjectTree(
        objectDataDefinitionId: $objectDataDefinitionId
        layoutSetId: $site
      ) {
        uiObjectWithChildTreeR {
          uiObjectChildTree
        }
      }
    }
  }
`
