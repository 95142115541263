export const BUTTON = "BUTTON"
export const CAROUSEL = "CAROUSEL"
export const CAROUSELITEM = "CAROUSELITEM"
export const LAYOUT = "LAYOUT"
export const LAYOUTMASK = "LAYOUTMASK"
export const LAYOUTSET = "LAYOUTSET"
export const TEXTBLOCK = "TEXTBLOCK"
export const IMAGE = "IMAGE"
export const HORIZONTALMENUBAR = "HORIZONTALMENUBAR"
export const HORIZONTALMENUBARITEM = "HORIZONTALMENUBARITEM"
export const HORIZONTALMENUBARNAV = "HORIZONTALMENUBARNAV"
export const HORIZONTALMENUBARNAVDROPDOWN = "HORIZONTALMENUBARNAVDROPDOWN"
export const BADGE = "BADGE"
export const CONTAINER = "CONTAINER"
export const ROW = "ROW"
export const COLUMN = "COLUMN"
export const CUSTOMCONTAINER = "CUSTOMCONTAINER"
export const ICON = "ICON"
export const HEADER = "HEADER"
export const LINK = "LINK"
export const TIME = "TIME"

export const PAGELINK = "PAGELINK"
export const PAGETAGS = "PAGETAGS"
export const PAGEAUTHOR = "PAGEAUTHOR"
export const PAGEDESCRIPTION = "PAGEDESCRIPTION"

export const PAGELISTING = "PAGELISTING"
//  Form Inputs
export const FORM = "FORM"
export const INPUT = "INPUT"
export const FILE = "FILE"
export const RANGE = "RANGE"
export const SELECT = "SELECT"
export const CHECK = "CHECK"
export const TEXTAREA = "TEXTAREA"

//AccordiAn
export const ACCORDION = "ACCORDION"
export const ACCORDIONITEM = "ACCORDIONITEM"

export const PHRASE = "PHRASE"
export const PHRASELIST = "PHRASELIST"

export const LISTITEM = "LISTITEM"
export const ORDEREDLIST = "ORDEREDLIST"
export const UNORDEREDLIST = "UNORDEREDLIST"

export const SVG = "SVG"
export const DATE = "DATE"

export const FORMATEDDATE = "FORMATEDDATE"
export const HTMLINPUT = "HTMLINPUT"

export const GOOGLERECAPTCHA = "GOOGLERECAPTCHA"
export const PICTURE = "PICTURE"
export const MEDIAWITHPREVIEW = "MEDIAWITHPREVIEW"
export const RAWDATA = "RAWDATA"

export const BOOLEAN = "BOOLEAN"
export const SCRIPT = "SCRIPT"

export const NAVIGATION = "NAVIGATION"
export const NAVIGATION_ITEM = "NAVIGATION_ITEM"
export const NAVIGATION_DROPDOWN = "NAVIGATION_DROPDOWN"
export const NAVIGATION_DROPDOWN_ITEM = "NAVIGATION_DROPDOWN_ITEM"
export const NAVIGATION_SUB_DROPDOWN = "NAVIGATION_SUB_DROPDOWN"
export const NAVIGATION_SUB_DROPDOWN_ITEM = "NAVIGATION_SUB_DROPDOWN_ITEM"

export const FORM_SELECT_LIST = "FORM_SELECT_LIST"
export const SELECT_LIST = "SELECT_LIST"
export const PAGE_DATA = "PAGE_DATA"

/* BREADCRUMB*/
export const BREADCRUMB = "BREADCRUMB"
export const ADD_TO_CART = "ADD_TO_CART"
/* MODAL or POPUP */
export const MODAL = "POP_UP"

/* DRAWER: SLIDE IN */
export const DRAWER = "DRAWER"

// MEGAMENU
export const MEGAMENU = "MEGA_MENU"
// STEPPER
export const STEPPER = "STEPPER"
export const STEPPERITEM = "STEPPERITEM"

//CART
export const CART = "CART"
export const CARTITEM = "CART_ITEM"

export const CHECKBOX = "CHECKBOX"
export const TOGGLE = "TOGGLE"
export const NON_PUBLIC_DATA = "NON_PUBLIC_DATA"
export const ICON_DYNAMIC = "ICON_DYNAMIC"
export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const PAYMENT = "PAYMENT"
export const DYNAMIC_CONTAINER = "DYNAMIC_CONTAINER"

// Button Types
export const Type_object = "ToggleModal"
export const State_Object = "ToggleDrawer"
export const ADDTOCART = "ADDCART"
export const REMOVEFROMCART = "removefromcart"
export const VIDEO = 'VIDEO'
export const FORMTOGGLE = "FORMTOGGLE"

