import React from "react"
// import ContainerBuilder from "./Components/Layout/container"
// import RowBuilder from "./Components/Layout/row"
// import ColumnBuilder from "./Components/Layout/col"

// import NavBarBuilder from "./Components/NavBar/index"

// import TextBlock from "./Components/Textblock.jsx"

// import CustomContainer from "./Components/CustomContainer"
// import IconBuilder from "./Components/Icon"

// import NavBarItem from "./Components/NavBar/NavigationItem"
// import NavigationDropDown from "./Components/NavBar/NavigationDropDown"
// import NavigationDropDownItem from "./Components/NavBar/NavigationDropDownItem"
import * as Builder from "./Types"

// import HeaderBuilder from "./Components/Headers"
// import AchorLinkBuilder from "./Components/AnchorLink"

// import CarouselBuilder from "./Components/Carousel"

// import ImageBuilder from "./Components/Image"
// import PhraseBuilder from "./Components/Phrase"

// import PageListing from "./Components/PageListing"
// import PageLink from "./Components/PageListing/Components/PageLink"
// import PageAuthor from "./Components/PageListing/Components/PageAuthor"
// import PageDescription from "./Components/PageListing/Components/PageDescription"

// import PhraseList from "./Components/PhraseList"
// import FormInput from "./Components/Inputs/index"
// import FormTextArea from "./Components/Inputs/TextArea"

// import TimeBuilder from "./Components/Time"
// import DateBuilder from "./Components/Date"
// import FormatedDate from "./Components/FormatedDate"

// import ListItemBuilder from "./Components/List/item"
// import UnOrderedListBuilder from "./Components/List/UnOrdered"
// import OrderedListBuilder from "./Components/List/Ordered"

//import ButtonBuilder from "./Components/Button"
// import HTMLInputTypeConfig from "./Components/Input/index"
// import { PageStateContext } from "../index"

// import SVGBuilder from "./Components/SvgBuilder"
// import FormBuilder from "./Components/Form"
// import CarouselItem from "./Components/Carousel/item"

// import Accordion from "./Components/Accordion/index"
// import AccordionItem from "./Components/Accordion/item"

// import GoogleRecaptcha from "./Components/GoogleRecaptcha"
// import Picture from "./Components/Picture.jsx"
// import MediaWithPreview from "./Components/MediaWithPreview"
// import RawData from "./Components/RawData"
// import Boolean from "./Components/Boolean"
// import Script from "./Components/Script"
// import FormSelectList from "./Components/FormSelectList"
// import SelectList from "./Components/SelectList"
// import PageData from "./Components/PageData/index"
// import BreadCrumb from "./Components/BreadCrumb"
//import ModalComponent from "./Components/Modal/Modal"
// import DrawerTest from "./Components/Drawer/Drawer"

// import StepperComponent from "./Components/Stepper/Stepper"
// import Cart from "./Components/Cart/Cart"
// import CartButton from "./Components/CartButton/CartButton"
// import CartItem from "./Components/Cart/CartItem"
// import Checkboxes from "./Components/Checkbox/Checkboxes"
// import StepperItem from "./Components/Stepper/StepperItem"
// import Toggle from "./Components/Toggle/Toggle"
// import DynamicIcon from "./Components/Dynamic Icon/DynamicIcon"
// import Login from "./Components/Login/Login"
// import NonPublicData from "./Components/PageData/Fields/NonPublicData"
import { PageStateContext } from "../PageStateContext"
// import DynamicContainer from "./Components/DynamicContainer/DynamicContainer"

import loadable from "@loadable/component"
const NavBarItem = loadable(() => import("./Components/NavBar/NavigationItem"))
const NavigationDropDown = loadable(() =>
  import("./Components/NavBar/NavigationDropDown")
)
const NavigationDropDownItem = loadable(() =>
  import("./Components/NavBar/NavigationDropDownItem")
)
const HeaderBuilder = loadable(() => import("./Components/Headers"))
const AchorLinkBuilder = loadable(() => import("./Components/AnchorLink"))
const CarouselBuilder = loadable(() => import("./Components/Carousel"))

const ImageBuilder = loadable(() => import("./Components/Image"))
const PhraseBuilder = loadable(() => import("./Components/Phrase"))

const PageListing = loadable(() => import("./Components/PageListing"))
const PageLink = loadable(() =>
  import("./Components/PageListing/Components/PageLink")
)
const PageAuthor = loadable(() =>
  import("./Components/PageListing/Components/PageAuthor")
)
const PageDescription = loadable(() =>
  import("./Components/PageListing/Components/PageDescription")
)

const PhraseList = loadable(() => import("./Components/PhraseList"))
const FormInput = loadable(() => import("./Components/Inputs/index"))
const FormTextArea = loadable(() => import("./Components/Inputs/TextArea"))

const TimeBuilder = loadable(() => import("./Components/Time"))
const DateBuilder = loadable(() => import("./Components/Date"))
const FormatedDate = loadable(() => import("./Components/FormatedDate"))
const ListItemBuilder = loadable(() => import("./Components/List/item"))
const UnOrderedListBuilder = loadable(() =>
  import("./Components/List/UnOrdered")
)
const OrderedListBuilder = loadable(() => import("./Components/List/Ordered"))

const ButtonBuilder = loadable(() => import("./Components/Button"))
const HTMLInputTypeConfig = loadable(() => import("./Components/Input/index"))

const SVGBuilder = loadable(() => import("./Components/SvgBuilder"))
const FormBuilder = loadable(() => import("./Components/Form"))
const CarouselItem = loadable(() => import("./Components/Carousel/item"))

const Accordion = loadable(() => import("./Components/Accordion/index"))
const AccordionItem = loadable(() => import("./Components/Accordion/item"))

const GoogleRecaptcha = loadable(() => import("./Components/GoogleRecaptcha"))
const Picture = loadable(() => import("./Components/Picture.jsx"))
const MediaWithPreview = loadable(() => import("./Components/MediaWithPreview"))
const RawData = loadable(() => import("./Components/RawData"))
const Boolean = loadable(() => import("./Components/Boolean"))
const Script = loadable(() => import("./Components/Script"))
const FormSelectList = loadable(() => import("./Components/FormSelectList"))
const SelectList = loadable(() => import("./Components/SelectList"))
const PageData = loadable(() => import("./Components/PageData/index"))
const BreadCrumb = loadable(() => import("./Components/BreadCrumb"))
const ModalComponent = loadable(() => import("./Components/Modal/Modal"))
const DrawerTest = loadable(() => import("./Components/Drawer/Drawer"))
const StepperComponent = loadable(() => import("./Components/Stepper/Stepper"))
const Cart = loadable(() => import("./Components/Cart/Cart"))
const CartButton = loadable(() => import("./Components/CartButton/CartButton"))
const CartItem = loadable(() => import("./Components/Cart/CartItem"))
const Checkboxes = loadable(() => import("./Components/Checkbox/Checkboxes"))
const StepperItem = loadable(() => import("./Components/Stepper/StepperItem"))
const Toggle = loadable(() => import("./Components/Toggle/Toggle"))
const DynamicIcon = loadable(() =>
  import("./Components/Dynamic Icon/DynamicIcon")
)
const Login = loadable(() => import("./Components/Login/Login"))
const NonPublicData = loadable(() =>
  import("./Components/PageData/Fields/NonPublicData")
)
const ContainerBuilder = loadable(() => import("./Components/Layout/container"))
const RowBuilder = loadable(() => import("./Components/Layout/row"))
const ColumnBuilder = loadable(() => import("./Components/Layout/col"))

const NavBarBuilder = loadable(() => import("./Components/NavBar/index"))

const TextBlock = loadable(() => import("./Components/Textblock.jsx"))

const CustomContainer = loadable(() => import("./Components/CustomContainer"))
const IconBuilder = loadable(() => import("./Components/Icon"))
const DynamicContainer = loadable(() =>
  import("./Components/DynamicContainer/DynamicContainer")
)
const Video = loadable(() => import("./Components/Video/Video"))

const Tags = loadable(() => import("./Components/PageTags/PageTags"))
export default function uiObjectInstanceFactory(tree, page) {
  if (tree) {
    return (
      <>
        {tree.map(node => {
          return (
            <PageStateContext.Consumer key={node.uiObjectId}>
              {pageContext => (
                <UiObjectInstanceIterator
                  page={page}
                  object={node}
                  pageData={pageContext}
                  websiteVariables={pageContext?.websiteVariables}
                />
              )}
            </PageStateContext.Consumer>
          )
        })}
      </>
    )
  } else return null
}

export function UiObjectInstanceIterator(props) {
  // get type of node

  const { type } = props.object
  switch (type) {
    case Builder.NAVIGATION_DROPDOWN_ITEM:
      return <NavigationDropDownItem {...props} />
    case Builder.NAVIGATION_DROPDOWN:
      return <NavigationDropDown {...props} />
    case Builder.NAVIGATION_SUB_DROPDOWN_ITEM:
      return <NavigationDropDownItem {...props} />
    case Builder.PAGE_DATA:
      return <PageData {...props} />
    case Builder.SELECT_LIST:
      return <SelectList {...props} />
    case Builder.FORM_SELECT_LIST:
      return <FormSelectList {...props} />
    case Builder.SCRIPT:
      return <Script {...props} />
    case Builder.BOOLEAN:
      return <Boolean {...props} />
    case Builder.RAWDATA:
      return <RawData {...props} />
    case Builder.MEDIAWITHPREVIEW:
      return <MediaWithPreview {...props} />
    case Builder.PICTURE:
      return <Picture {...props} />
    case Builder.GOOGLERECAPTCHA:
      return <GoogleRecaptcha {...props} />
    case Builder.ACCORDION:
      return <Accordion {...props} />
    case Builder.ACCORDIONITEM:
      return <AccordionItem {...props} />
    case Builder.CAROUSELITEM:
      return <CarouselItem {...props} />
    case Builder.FORM:
      return <FormBuilder {...props} />
    case Builder.HTMLINPUT:
      return <HTMLInputTypeConfig {...props} />
    case Builder.BUTTON:
      return <ButtonBuilder {...props} />
    case Builder.FORMATEDDATE:
      return <FormatedDate {...props} />
    case Builder.DATE:
      return <DateBuilder {...props} />
    case Builder.SVG:
      return <SVGBuilder {...props} />
    case Builder.UNORDEREDLIST:
      return <UnOrderedListBuilder {...props} />
    case Builder.ORDEREDLIST:
      return <OrderedListBuilder {...props} />
    case Builder.LISTITEM:
      return <ListItemBuilder {...props} />
    case Builder.TIME:
      return <TimeBuilder {...props} />
    case Builder.PHRASELIST:
      return <PhraseList {...props} />
    case Builder.TEXTAREA:
      return <FormTextArea {...props} />
    case Builder.INPUT:
      return <FormInput {...props} />
    case Builder.PAGEAUTHOR:
      return <PageAuthor {...props} />
    case Builder.PAGEDESCRIPTION:
      return <PageDescription {...props} />
    case Builder.PAGELINK:
      return <PageLink {...props} />
    case Builder.PAGELISTING:
      return <PageListing {...props} />
    case Builder.PHRASE:
      return <PhraseBuilder {...props} />
    case Builder.IMAGE:
      return <ImageBuilder {...props} />
    case Builder.CAROUSEL:
      return <CarouselBuilder {...props} />
    case Builder.LINK:
      return <AchorLinkBuilder {...props} />
    case Builder.HEADER:
      return <HeaderBuilder {...props} />
    case Builder.HORIZONTALMENUBARITEM:
    case Builder.NAVIGATION_ITEM:
      return <NavBarItem {...props} />
    case Builder.ICON:
      return <IconBuilder {...props} />
    case Builder.CUSTOMCONTAINER:
    case "CUSTOM_CONTAINER":
      return <CustomContainer {...props} />
    case Builder.CONTAINER:
      return <ContainerBuilder {...props} />
    case Builder.ROW:
      return <RowBuilder {...props} />
    case Builder.HORIZONTALMENUBAR:
    case Builder.NAVIGATION:
      return <NavBarBuilder {...props} />
    case Builder.COLUMN:
      return <ColumnBuilder {...props} />
    case Builder.TEXTBLOCK:
      return <TextBlock {...props} />
    case Builder.BREADCRUMB:
      return <BreadCrumb {...props} />
    case Builder.CART:
      return <Cart {...props} />
    case Builder.CARTITEM:
      return <CartItem {...props} />
    case Builder.MODAL:
      return <ModalComponent {...props} />
    case Builder.DRAWER:
      return <DrawerTest {...props} />
    case Builder.STEPPER:
      return <StepperComponent {...props} />
    case Builder.ADD_TO_CART:
      return <CartButton {...props} />
    case Builder.STEPPERITEM:
      return <StepperItem {...props} />
    case Builder.CHECKBOX:
      return <Checkboxes {...props} />
    case Builder.TOGGLE:
      return <Toggle {...props} />
    case Builder.NON_PUBLIC_DATA:
      return <NonPublicData {...props} />
    case Builder.ICON_DYNAMIC:
      return <DynamicIcon {...props} />
    case Builder.LOGIN:
      return <Login {...props} />
    case Builder.DYNAMIC_CONTAINER:
      return <DynamicContainer {...props} />
    case Builder.VIDEO:
      return <Video {...props} />
    case Builder.PAGETAGS:
      return <Tags {...props} />

    default:
      return (
        <div className="bg-warning">
          {props.object.type} type builder not compatible
        </div>
      )
  }
}
